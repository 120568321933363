// DownloadLink.jsx
import React from "react";

const DownloadLink = ({link, onDownloadClick}) => {
  return (
    <>
      <label className="text-lg text-black" htmlFor="data">
        Please download the digitized csv
      </label>
      <button onClick={() => onDownloadClick(link)} className="btn hover:btn-neutral" type="button">
        Download CSV
      </button>
    </>
  );
};

export default DownloadLink;
