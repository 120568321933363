// Footer.jsx
import React from 'react';

const Footer = () => {
  return (
    <footer className="fixed inset-x-0 bottom-0 bg-transparent text-left font-bold text-fuchsia px-2 py-2">
      Software Version v1.0.0
    </footer>
  );
};

export default Footer;
