import '../index.css';

export default function Navigation() {
  return (
    <nav className="w-full flex bg-fuchsia text-white">
      <div className="w-full flex justify-between items-center p-3 text-l text-foreground">
        <div className="flex items-center gap-4">
          <h1 className="py-2 no-underline font-bold text-2xl">
            SpOvum OCR
          </h1>
          {/* <a href="/" className="py-2 px-3 no-underline hover:underline">
            FAQ
          </a> */}
        </div>
      </div>
    </nav>
  );
}
