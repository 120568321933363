// App.jsx
import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  useParams,
  Routes,
  Outlet,
} from "react-router-dom";
import '../index.css';
import "daisyui/dist/full.css";
import DownloadLink from "./DownloadLink";
import {
  uploadFile,
  generateApiKey,
  setupSSE,
} from "../services/ApiService";
import AlertSuccess from "./AlertSuccess";
import AlertFailure from "./AlertFailure";
import routeConfig from "../routeInfo.json";

const routeInfo = routeConfig.ClinicBranchRoute;

const FileUploadDownloadPage = () => {
  const { clinicId, branchId } = useParams();
  const [file, setFile] = useState(null);
  const [downloadLink, setDownloadLink] = useState("");
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  // Provide a fallback empty string for the environment variable
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleFileChange = (e) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      setFile(files[0]);
    }
  };

  const handleFileUpload = async (e) => {
    // prevent page refresh
    e.preventDefault();

    setLoading(true); // Start loading
    setDownloadLink(""); // Reset download link
    setSuccessMessage(null); // Reset success message
    setErrorMessage(null); // Reset error message

    if (clinicId && branchId) {
      try {
        const apiKey = generateApiKey(clinicId, branchId);
        console.log("Inside file upload")
        const data = await uploadFile(file, apiUrl, clinicId, branchId, apiKey);
        const jobId = data.job_id;

        if (jobId) {
          setupSSE(
            apiUrl,
            apiKey,
            jobId,
            clinicId,
            branchId,
            (event) => {
              if (event.data === "show_download_button=true") {
                setDownloadLink(
                  `${apiUrl}/${clinicId}/${branchId}/fetch_csv/${jobId}/?api_key=${apiKey}`
                );
                setErrorMessage(null);
                setSuccessMessage("Your file is ready for download.");
                setLoading(false); // Stop loading
              }
            },
            () => {
              console.error("SSE Connection Failed");
              setSuccessMessage(null);
              setErrorMessage("Streaming connection failed.");
              setLoading(false); // Stop loading
            }
          );
        } else {
          console.error("Job ID not received");
          setSuccessMessage(null);
          setErrorMessage("Failed to receive a job ID.");
          setLoading(false); // Stop loading
        }
      } catch (error) {
        console.error("Upload failed", error);
        setSuccessMessage(null);
        setErrorMessage("File upload failed.");
        setLoading(false); // Stop loading
      }
    }
  };

  // Function to handle the download click
  const handleDownloadClick = async (link) => {
    if (!link) return; // Check if the download link is available

    try {
      const response = await fetch(link, {
        method: 'GET',
      });

      if (response.ok) {
        // Proceed with the download if the file is available
        window.location.href = link; // This triggers the download
      } else {
        // Handle unavailable file or other errors
        throw new Error('File is not available for download.');
      }
    } catch (error) {
      // Handle errors, such as network issues or file not found
      console.error("Error downloading the file:", error);
      setSuccessMessage(null);
      setErrorMessage("Error downloading the file. Please try again later.");
    }
  };


  return (
    <>
    <form
        className="flex flex-col items-center justify-center gap-4"
        onSubmit={handleFileUpload}
        encType="multipart/form-data" // Added for handling file uploads
        method="post"
        action={apiUrl}
      >
        <label className="text-md text-black" htmlFor="data">
          Upload a PDF or JPG file
        </label>
        <div className="flex flex-col items-center gap-4">
        <input
          className="file-input file-input-bordered w-full max-w-xs"
          type="file"
          name="file"
          accept=".pdf, .jpg"
          onChange={handleFileChange}
          // ref={fileInputRef}
          required
        />
        <button type="submit" className="btn hover:btn-neutral">
          Upload File
          {loading && (<span className="loading loading-spinner loading-md"></span>)}
        </button>
      </div>
      {loading && (
            <p className="ml-2 text-fuchsia">Loading ...</p>
            
          )}
      {downloadLink && (
        <DownloadLink link={downloadLink} onDownloadClick={handleDownloadClick} />
      )}
      </form>

      <div className="absolute bottom-0">
        {successMessage && (
          <AlertSuccess
            title="File Digitized Successfully!"
            message={successMessage}
            visible={successMessage != null}
          />
        )}
        {errorMessage && (
          <AlertFailure
            title="Digitization failed!"
            message={errorMessage}
            visible={errorMessage != null}
          />
        )}
      </div>
    </>
  );
};

const PageNotFound = () => {
  return <>Page Not Found</>;
};

const ClinicBranchRoute = () => {
  const { randomString, clinicId, branchId } = useParams();

  if (randomString && clinicId && branchId) {
    // Validate clinicId and branchId as integers
    const validatedClinicId = parseInt(clinicId, 10);
    const validatedBranchId = parseInt(branchId, 10);

    console.log(clinicId);
    console.log(branchId);
    // Check if clinicId and branchId are valid integers
    if (isNaN(validatedClinicId) || isNaN(validatedBranchId)) {
      console.log("Either clinic or branch ID is not a valid integer");
      // Redirect to Page Not Found if either is not a valid integer
      return <PageNotFound />;
    }

    // Create a tuple key to match against the JSON keys
    const key = `(${validatedClinicId}, ${validatedBranchId})`;

    // Check if the key exists and the randomString matches the value
    if (routeInfo[key] === randomString) {
      return <FileUploadDownloadPage />;
    } else {
      // If the tuple does not exist or randomString does not match
      return <PageNotFound />;
    }
  }

  // If any of the parameters are undefined, redirect to Page Not Found
  return <PageNotFound />;
};

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Outlet />}>
          <Route path="/" element={<PageNotFound />} />
          <Route path="/:randomString/:clinicId/:branchId" element={<ClinicBranchRoute />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRouter;
