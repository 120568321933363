import AppRouter from "./components/App";
import Navigation from "./components/Navigation";

function App() {
  return (
    <div className="min-h-screen flex flex-col">
      <Navigation />
      <div className="flex-1 flex flex-col items-center justify-center relative">
        <AppRouter />
      </div>
    </div>
  );
}

export default App;


