// ApiService.ts
import axios from 'axios';
import { sha256 } from 'js-sha256';

export const generateApiKey = (clinicId, branchId) => {
  // const dateStr = new Date().toISOString().split('T')[0];
  // Format the current date as YYYY-MM-DD in IST timezone
  const dateStr = new Intl.DateTimeFormat('en-CA', {
    timeZone: 'Asia/Kolkata',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }).format(new Date()).replace(/\//g, '-');
  const rawKey = `spovum/${dateStr}/${clinicId}/${branchId}/ocr`;
  return sha256(rawKey);
};

export const uploadFile = async (file, apiUrl, clinicId, branchId, apiKey) => {
  const formData = new FormData();
  console.log("Upload file service is called")
  formData.append('file', file);
  console.log(`${apiUrl}/${clinicId}/${branchId}/upload-pdf/?api_key=${apiKey}`)
  const response = await axios.post(`${apiUrl}/${clinicId}/${branchId}/upload-pdf/?api_key=${apiKey}`, formData);
  return response.data;
};

export const setupSSE = (
  apiUrl,
  apiKey,
  jobId,
  clinicId,
  branchId,
  onMessageCallback,
  onErrorCallback) => {

  const eventSource = new EventSource(`${apiUrl}/${clinicId}/${branchId}/stream/${jobId}?api_key=${apiKey}`);

  // Wrap the original onMessageCallback to intercept specific messages
  const wrappedOnMessageCallback = (event) => {
    // Check if the message indicates that the download button should be shown
    if (event.data === "show_download_button=true") {
      onMessageCallback(event); // Ensure the original callback is called with the event
      
      // Close the EventSource connection
      console.log("Closing SSE connection due to download ready message.");
      eventSource.close();
    } else {
      // For all other messages, just call the original callback
      onMessageCallback(event);
    }
  };

  // Use the wrapped callback for onmessage events
  eventSource.onmessage = wrappedOnMessageCallback;

  eventSource.onerror = () => {
    onErrorCallback();
    // Close the EventSource on error
    eventSource.close();
  };

  // Return a function that allows for manual closing of the EventSource connection
  return () => {
    console.log("Closing SSE connection through cleanup function.");
    eventSource.close();
  };
};
